var domain = document.location.protocol + '//' + document.location.hostname;

$(function(){

  $('.fa-play-circle-o').on("click",function(){
    $('body > nav.navbar').attr('style','z-index:1 !important');
  });
  $('.lightbox, .lightbox-close').on("click",function(){
    $('body > nav.navbar').removeAttr('style');
  });

  // open raw xml mode in new tab on ctrl+alt+r
  var url = window.location + '';
  if (location.href.split(location.host)[1].indexOf('/txd/page/') == 0) {
    $(window).keypress(function(e){
      var code = e.keyCode || e.which;
      if(code == 174 && e.ctrlKey && e.altKey) {
        var urlBase = url.split('?')[0];
        var urlParams = url.split('?')[1] ? '?' + url.split('?')[1] : '';
        var newUrl = urlBase + '/raw' + urlParams;
        window.open(newUrl,'_blank');
      }
    });
  }

  $('.show_hide_sub_navigation').on('click', function(e) {
    e.preventDefault();
    if($(this).hasClass('open')) {
      $(this).removeClass('open');
      $(this).next('ul').slideUp();
    } else {
      $(this).addClass('open');
      $(this).next('ul').slideDown();
    }
  });

  // website exit
  $.expr[':'].external = function (a) {
    return a.href.indexOf('http') == 0 && a.href.indexOf(domain) != 0;
  };
  // $('a:external').each(function(){
  //   $(this).attr('target','_blank');
  // });

  // build email links (spam prevention)
	$('a.email').each(function(){
		var pre       = $(this).data('pre');
		var after     = $(this).data('after');
		var textPre   = $(this).data('text-pre');
		var textAfter = $(this).data('text-after');
		$(this).attr('href', 'mailto:' + pre + '@' + after);
		if (textPre) $(this).text(textPre + '@' + textAfter);
	});

	/* left/right placement of tooltips */
	if ($('#content-in')[0]) {
    var leftPosContent = $('#content-in').offset().left;
    var widthContent   = $('#content-in').width();
    $('.hint--bottom').each(function(){
      var leftPos = $(this).offset().left;
      if (widthContent / 2 < leftPos - leftPosContent) $(this).addClass('hint--leftside');
    });
	}

	/* collapse and popup large tables */
  var pageWidth = $('#content-full').width();
	$('.table-zoom')
	.each(function(){
	  var minMargin   = 200;
	  var zoombutton  = $(this);
	  var table       = zoombutton.parent();
	  var tableHeight = table.height();
	  var positionTop = tableHeight > 500 ? minMargin : tableHeight / 2;

	  zoombutton.css('top',positionTop+'px');

	  if (tableHeight > 500) {
   	  var tableTop = table.offset().top;
      $(window).scroll(function(){
        var tableScrollPos = $(this).scrollTop() - tableTop;
        //console.log('tableScrollPos: '+ tableScrollPos);
        //console.log('tableHeight - tableTop - minMargin: '+ (tableHeight - tableTop - minMargin));
        // NOT WORKING YET!
   	    if (tableScrollPos > 0 && tableScrollPos < tableHeight - tableTop - minMargin) {
   	      console.log('test');
     	    positionTop = minMargin + tableScrollPos;
     	    zoombutton.css('top',positionTop+'px');
   	    }
   	  });
   	}

	  $(this).nextAll('table').css('width',pageWidth - 40);
	})
	.click(function(){
	  $(this).parent().addClass('popup');
	})
	;

	$('.table-close').click(function(){
	  $(this).parent().removeClass('popup');
	});

	/* fix left menu on page scroll */
  $(window).scroll(function(){
    if (window.innerWidth > 768) {
      var navHeight  = $('#sub-navigation').height();
      var pageHeight = $('#content-full').height();
      var maxMT      = pageHeight - navHeight - 20;
      if ($(window).height() > navHeight + 20 && pageHeight > navHeight && pageHeight > 500) {
        var scrollPos = $(this).scrollTop();
        var margintop = scrollPos > 50 ? scrollPos - 50 : 0;
        margintop     = margintop > maxMT ? maxMT : margintop;
        $('#sub-navigation').css('margin-top',margintop);
      }
    }
  });


	window.setInterval(function(){
	  $('.slider-container-inner').toggleClass('blink');
	}, 600);


});
